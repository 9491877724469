import { EP_USR, MENU_OPTIONS } from '../constants';

export const getSidebarOptionsFromGroups = groups => {
  const pages = [];
  if (groups.length) {
    groups.forEach(group => {
      const [groupId] = group.split('|');
      const foundGroup = EP_USR.find(u => u.id === groupId);
      if (foundGroup) {
        // To do: verify why this fails only during deployment
        // eslint-disable-next-line
        const newPages = MENU_OPTIONS[foundGroup.key] ?? [];
        newPages.forEach(newPage => {
          if (!pages.find(page => page.url === newPage.url)) {
            pages.push(newPage);
          }
        });
      }
    });
  }
  return pages;
};

export const isAuthorized = (groups, route) => {
  let res = false;
  if (groups) {
    groups.forEach(group => {
      const [groupId] = group.split('|');
      const foundGroup = EP_USR.find(u => u.id === groupId);
      if (route === '/' || MENU_OPTIONS[foundGroup?.key]?.find(p => p.url === route)) {
        res = true;
      }
    });
  }
  return res;
};

//Hacer que sea por el key y no por el id
export const getBadgeImageFromGroups = groups => {
  let badgeImage = ''; // Inicializamos con un valor vacío
  if (groups.length) {
    for (const group of groups) {
      const [groupId] = group.split('|');
      const foundGroup = EP_USR.find(u => u.id === groupId);
      if (foundGroup) {
        // Asignar la imagen del badge y romper el ciclo en cuanto encontremos la primera
        badgeImage = '/images/insignia/logoBusinessPartner.png';
        break; // Salimos del ciclo una vez que encontramos una coincidencia
      }
    }
  }
  return badgeImage;
};
