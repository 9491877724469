import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Container, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import DaysLeftForElimination from '../RegistryComponents/DaysLeftForElimination';
import RegistryDocumentationForm from '../RegistryComponents/RegistryDocumentationForm';
import RegistryEnd from '../RegistryComponents/RegistryEnd';
import RegistryFiscalDataForm from '../RegistryComponents/RegistryFiscalDataForm';
import RegistryForm from '../RegistryComponents/RegistryForm';
import RegistryCuestions from '../RegistryComponents/RegistryQuestions';
import RegistryTermsconditions from '../RegistryComponents/RegistryTermsandConditions';

const CustomStepper = styled(Stepper)({
  '& .MuiStepIcon-root': {
    color: '#f4c6ab',
    transform: 'scale(1.3)'
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: '#e85d0c'
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: '#e85d0c'
  },
  '& .MuiStepIcon-text': {
    display: 'none'
  },
  '& .MuiStepLabel-label': {
    color: '#353964'
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: '#282B4C'
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: '#282B4C'
  }
});

const buttonStyles = {
  '&:hover': {
    backgroundColor: '#e85d0c',
    border: '1px solid #e85d0c',
    color: 'white'
  },
  border: '1px solid #e85d0c',
  borderRadius: '20px',
  color: '#e85d0c',
  minWidth: '120px',
  mt: 2,
  mx: 1
};

export default function RegistryComponent() {
  const { t } = useTranslation('registry');
  const { accounts } = useMsal();
  const [sessionData, setSessionData] = useState({});
  const [days, setDays] = useState(0);
  const steps = [t('steps.step1'), t('steps.step2'), t('steps.step3'), t('steps.step4')];
  const [activeStep, setActiveStep] = useState(0);
  const [data, setFormData] = useState({
    actaConstitutiva: null,
    actividadComercial: '',
    apellidoMaterno: '',
    apellidoPaterno: '',
    calleFiscal: '',
    captcha: '',
    captchaInput: '',
    ciudadFiscal: '',
    codigoPostal: '',
    codigoPostalFiscal: '',
    coloniaFiscal: '',
    comprobanteDomicilio: null,
    direccionFiscal: '',
    estadoFiscal: '',
    experienciaTI: '',
    identificacionOficial: null,
    localComercial: '',
    nombreComercial: '',
    nombreFiscal: '',
    nombres: '',
    notificacionCorreo: '',
    notificacionPreferencia: '',
    notificacionTelefono: '',
    numeroExteriorFiscal: '',
    numeroInteriorFiscal: '',
    pais: 'México',
    paisFiscal: 'México',
    rfc: '',
    situacionFiscal: null,
    telefono: '',
    telefonoFiscal: '',
    termsAccepted: false, // Nuevo estado para términos y condiciones
    tipoPersona: 'Física',
    // El tipo de socio se determina en base a las respuestas de las preguntas 1= socio de negocio, 2= proveedor de sistemas, 3= influencer
    tipoSocio: ''
  });
  // Comprobante de domicilio, identificacionOficial, actaConstitutiva, situacionFiscal

  const [errors, setErrors] = useState({});

  // Function to get the current number of days from the database
  const fetchDays = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/accountDeletionDays`)
      .then(response => {
        console.log('Response from API:', response.data);
        if (response.data && response.data.length > 0) {
          setDays(response.data[0].registro_dias);
        }
      })
      .catch(error => {
        console.error('Error fetching days:', error);
      });
  };

  useEffect(() => {
    const sessionData = accounts[0].idTokenClaims;
    console.log('sessionData:', sessionData);
    setSessionData(sessionData);
    generateCaptcha();
    fetchDays();
  }, [accounts]);

  const generateCaptcha = () => {
    const randomCaptcha = Math.random().toString(36).substring(2, 8);
    setFormData(currentData => ({ ...currentData, captcha: randomCaptcha }));
  };

  const handleNext = async () => {
    const validationErrors = await validateForm(data, activeStep);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (activeStep === steps.length - 1) {
        // Handle the form submission because this is the last step
        submitFormData();
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };
  // const generateRandomId = () => {
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   let result = '';
  //   const charactersLength = characters.length;
  //   for (let i = 0; i < 7; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // };

  const submitFormData = async () => {
    console.log('Data:', data);
    try {
      const id_factura = sessionData.sub;
      // Datos para la API de facturas
      const facturaData = {
        correo_electronico: data.correo,
        direccion_fiscal: `${data.calleFiscal} ${data.numeroExteriorFiscal}, ${data.coloniaFiscal}`,
        fifa: 'MEX',
        id_cliente: null,
        id_cp: data.codigoPostalFiscal,
        id_factura: id_factura,
        id_representante_legal: null,
        nombre_comercial: data.nombreComercial,
        numero_exteriorr: data.numeroExteriorFiscal,
        numero_interio: data.numeroInteriorFiscal || null, // Usar null si no hay valor
        razon_social: data.nombreFiscal, // Asumiendo que 'nombreFiscal' es la razón social
        rfc: data.rfc
      };

      // Datos para la API de clientes
      const clienteData = {
        apellido_materno: data.apellidoMaterno,
        apellido_paterno: data.apellidoPaterno,
        correo: data.correo,
        estado_de_solicitud: 'Pendiente',
        fifa: 'MEX',
        id_cp: data.codigoPostal,
        nombre: data.nombres,
        region: 1,
        telefono: parseInt(data.telefono), // Convertir a número
        tipo_de_socio: data.tipoSocio
      };

      // Realizar la petición POST para facturas
      const facturaResponse = await axios.post(`${process.env.REACT_APP_API_URL}/facturas`, facturaData);
      console.log('Respuesta factura:', facturaResponse.data);
      const facturaId = facturaResponse.data.id_factura; // Asegúrate de obtener el ID correctamente de la respuesta

      // Preparar formData para documentos adjuntos
      const documentsFormData = new FormData();
      if (data.comprobanteDomicilio) documentsFormData.append('files', data.comprobanteDomicilio);
      if (data.identificacionOficial) documentsFormData.append('files', data.identificacionOficial);
      if (data.actaConstitutiva) documentsFormData.append('files', data.actaConstitutiva);
      if (data.situacionFiscal) documentsFormData.append('files', data.situacionFiscal);

      const docsResponse = await axios.post(`${process.env.REACT_APP_API_URL}/business-partner-docs/pid${facturaId}`, documentsFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Archivos subidos exitosamente:', docsResponse.data);

      // Realizar la petición POST para clientes
      if (data.tipoPersona === 'Fisica') {
        const clienteResponse = await axios.post(`${process.env.REACT_APP_API_URL}/clients`, clienteData);
        console.log('Respuesta cliente:', clienteResponse.data);
      }
    } catch (error) {
      console.error('Error en las peticiones POST:', error);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const validateForm = async (data, step) => {
    let newErrors = {};
    if (step === 0) {
      if (data.tipoPersona === 'Física') {
        if (!data.nombres) newErrors.nombres = t('errors.label');
        if (!/^[a-zA-Z\s]*$/.test(data.nombres)) {
          newErrors.nombres = t('errors.number');
        }
        if (!data.apellidoPaterno) newErrors.apellidoPaterno = t('errors.label');
        if (!data.apellidoMaterno) newErrors.apellidoMaterno = t('errors.label');
      } else if (data.tipoPersona === 'Moral') {
        if (!data.razonSocial) newErrors.razonSocial = t('errors.label');
        if (data.nombreComercial && data.nombreComercial.length < 2) newErrors.nombreComercial = t('errors.minLength', { min: 2 });
      }
      console.log('data:', data);
    } else if (step === 1) {
      if (!data.actividadComercial) newErrors.actividadComercial = t('errors.label');
      if (!data.localComercial) newErrors.localComercial = t('errors.label');
      if (!data.experienciaTI) newErrors.experienciaTI = t('errors.label');
    } else if (step === 2) {
      if (!data.rfc) newErrors.rfc = t('errors.label');
      if (!data.codigoPostalFiscal) {
        newErrors.codigoPostalFiscal = t('errors.label');
      } else if (!/^\d{5}$/.test(data.codigoPostalFiscal)) {
        newErrors.codigoPostalFiscal = t('errors.zipCode');
      } else {
        try {
          const responseCP = await axios.get(`${process.env.REACT_APP_API_URL}/cp/${data.codigoPostalFiscal}`);
          const dataCP = responseCP.data;
          if (dataCP.message !== 'SI existe código') {
            newErrors.codigoPostalFiscal = t('errors.noZipCode');
          }
        } catch (error) {
          console.log('Error en la búsqueda del CP:', error);
          newErrors.codigoPostalFiscal = t('errors.noZipCode');
        }
      }
      if (!data.coloniaFiscal) newErrors.coloniaFiscal = t('errors.label');
    } else if (step === 3) {
      if (!data.actaConstitutiva) newErrors.actaConstitutiva = t('errors.label');
      if (!data.comprobanteDomicilio) newErrors.comprobanteDomicilio = t('errors.label');
      if (!data.identificacionOficial) newErrors.identificacionOficial = t('errors.label');
      if (!data.situacionFiscal) newErrors.situacionFiscal = t('errors.label');
      if (!data.termsAccepted) {
        newErrors.termsAccepted = t('errors.terms');
      }
      if (!data.notificacionTelefono && !data.notificacionCorreo) {
        newErrors.preferenciaNotificacion = t('errors.notification');
      }

      if (data.captchaInput !== data.captcha) {
        newErrors.captchaInput = t('errors.captcha');
      }
    }
    return newErrors;
  };

  const handleChange = e => {
    const { name, checked, type } = e.target;

    let newValue = type === 'checkbox' ? checked : e.target.value;

    setFormData(prevData => {
      // Combinar las preferencias de notificación en una cadena o arreglo
      const newNotificationPreference =
        type === 'checkbox' && (name === 'notificacionTelefono' || name === 'notificacionCorreo')
          ? {
              ...prevData,
              notificacionCorreo: name === 'notificacionCorreo' ? checked : prevData.notificacionCorreo,
              notificacionTelefono: name === 'notificacionTelefono' ? checked : prevData.notificacionTelefono
            }
          : prevData;

      if (name === 'notificacionTelefono' || name === 'notificacionCorreo') {
        newNotificationPreference.notificacionPreferencia = `${newNotificationPreference.notificacionTelefono ? 'Teléfono' : ''}${newNotificationPreference.notificacionTelefono && newNotificationPreference.notificacionCorreo ? ', ' : ''}${newNotificationPreference.notificacionCorreo ? 'Correo Electronico' : ''}`;
      }

      return {
        ...newNotificationPreference,
        [name]: newValue
      };
    });

    // Resetear errores específicos al campo
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  // Función para obtener el contenido de cada paso
  const getStepContent = step => {
    switch (step) {
      case 0:
        return <RegistryForm errors={errors} formData={data} handleChange={handleChange} />;
      case 1:
        return <RegistryCuestions errors={errors} formData={data} handleChange={handleChange} />;
      case 2:
        return <RegistryFiscalDataForm errors={errors} formData={data} handleChange={handleChange} />;
      case 3:
        return (
          <>
            <RegistryDocumentationForm errors={errors} formData={data} handleChange={handleChange} />
            <RegistryTermsconditions data={data} errors={errors} handleChange={handleChange} />
          </>
        );
      default:
        return <RegistryEnd />;
    }
  };

  return (
    <Container maxWidth={false} sx={{ padding: { xs: 3 } }}>
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          sx={{
            height: { md: 'auto', xs: 200 },
            position: 'relative'
          }}
          xs={12}
        >
          {/* Imagen ilustrativa */}
          <img alt="Imagen" src="/images/registry/registry.svg" style={{ height: '100%', objectFit: 'cover', width: '100%' }} />
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'center' }} xs={12}>
          {/* Título del formulario */}
          <Typography
            component="h1"
            gutterBottom
            sx={{
              color: '#e85d0c',
              fontSize: { md: '2.8rem', xs: '2.2rem' },
              fontWeight: 'bold'
            }}
          >
            {t('title')}
          </Typography>
          {/* Descripción del formulario */}
          <Typography
            gutterBottom
            sx={{
              color: '#282B4C',
              fontSize: { md: '2rem', xs: '1.5rem' },
              fontWeight: 'bold',
              textAlign: 'justify'
            }}
          >
            {t('description')}
          </Typography>
          <DaysLeftForElimination data={sessionData} days={days} />
          {/* Stepper para mostrar los pasos */}
          <CustomStepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </CustomStepper>
          {/* Contenido del paso actual */}
          {getStepContent(activeStep)}
          <Box
            sx={{
              display: activeStep === steps.length ? 'none' : 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              pt: 2
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ ...buttonStyles, color: activeStep === 0 ? 'grey' : '#e85d0c' }}
            >
              {t('b_back')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button color="primary" onClick={handleNext} sx={buttonStyles}>
              {activeStep === steps.length - 1 ? t('b_end') : t('b_next')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
