import { useCallback, useState } from 'react';
import { useTheme } from '@emotion/react';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Box, Divider, Toolbar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getSidebarOptionsFromGroups } from 'src/lib/util/navigation';

import { default as logoInverse } from '../../images/logo-inverse.svg';
import { default as logo } from '../../images/logo.svg';

const { useMsal } = require('@azure/msal-react');

const Sidebar = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [pages, setPages] = useState();
  const theme = useTheme();
  const { i18n, t } = useTranslation('nav');
  const locale = i18n.resolvedLanguage;

  const isDarkTheme = theme.palette.mode === 'dark';
  const tokenGroups = accounts[0]?.idTokenClaims?.groups;

  if (!pages && tokenGroups) {
    setPages(getSidebarOptionsFromGroups(tokenGroups));
  }

  console.log(tokenGroups);

  const handleMenuOptionClick = useCallback(
    url => {
      if (url) navigate(url, { locale });
    },
    [locale, navigate]
  );

  return (
    <div>
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="center">
          <Link to="/">
            <img alt="EntryPay" src={isDarkTheme ? logoInverse : logo} style={{ marginTop: 8 }} width={40} />
          </Link>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {pages?.map(page => (
          <ListItem disablePadding key={page.url}>
            <ListItemButton onClick={() => handleMenuOptionClick(page.url)}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={t(page.label)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Sidebar;
