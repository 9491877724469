import BusinessClub from './business-club';
import Dashboard from './dashboard';
import GlobalConfig from './global-config';
import NotFound from './not-found';
import Profile from './profile';

const pagesData = [
  {
    element: <Dashboard />,
    path: '/',
    title: 'home'
  },
  {
    element: <>About</>,
    path: '/about',
    title: 'about'
  },
  {
    element: <BusinessClub />,
    path: '/business-club',
    title: 'businessClub'
  },
  {
    element: <GlobalConfig />,
    path: '/global-config',
    title: 'globalConfig'
  },
  {
    element: <NotFound />,
    path: '*',
    title: 'not-found'
  },
  {
    element: <Profile />,
    path: '/profile',
    title: 'profile'
  }
];

export default pagesData;
